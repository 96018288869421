import { ALLOWED_LANGUAGES } from 'constants/general.constants'
import { NO } from '@elo-kit/constants/general.constants'
import { BUSINESS_TYPES, TAX_SWITCHER_KEYS } from 'constants/userProfile.constants'

export const PROFILE_LANGUAGE = Object.keys(ALLOWED_LANGUAGES).reduce(
  (acc, lang) => ({ ...acc, [lang]: ALLOWED_LANGUAGES[lang].value }),
  {}
)

export const MIN_NAME_LENGTH = 3
export const MIN_PASSWORD_LENGTH = 8
export const MAX_PROFILE_NAME_LENGTH = 40
export const MIN_USERNAME_LENGTH = 4
export const MAX_USERNAME_LENGTH = 60
export const MAX_COMPANY_NAME_LENGTH = 50
export const PASSWORD_ALLOWED_SYMBOLS = '!"#$%&()*+,-./:;<=>?@[\\\\\\]^_{|}~'
export const NUMBER_COUNT_IN_PASS = 1
export const SPECIAL_CHARACTER_COUNT_IN_PASS = 1

export const AVATAR_CROP_PARAMS = {
  aspect: 1,
  width: 100,
  x: 0,
  y: 0,
}

export const AVATAR_LARGE_CROP_PARAMS = {
  aspect: 1,
  width: 200,
  height: 200,
  x: 0,
  y: 0,
}

export const COVER_CROP_PARAMS = {
  aspect: 16 / 9,
  width: 1920,
  x: 0,
  y: 0,
}

export const BANK_FORMATS = {
  iban: 'iban',
  bic: 'bic',
}
export const BANK_FORMATS_LIST = Object.values(BANK_FORMATS)

export const BANK_ACCOUNT_STATUSES = {
  notSent: 'not_sent',
  waiting: 'waiting',
  activated: 'activated',
  deactivated: 'deactivated',
  rejected: 'rejected',
  error: 'error',
  initState: 'init_state',
}

export const DEPRECATED_ELO_PUBLISHER_PROFILE = 'elo_publisher'

export const PROFILES = {
  seller: 'seller',
  publisher: 'publisher',
  eloPublisher: 'eloPublisher',
  teamMember: 'teamMember',
  salesTeamMember: 'salesTeamMember',
  payer: 'payer',
  ablefyAffiliate: 'ablefyAffiliate',
}

export const PROFILES_ROOT_PATH = {
  seller: 'cabinet',
  publisher: 'publish',
  elo_publisher: 'ablefy_affiliate',
  ablefy_affiliate: 'ablefy_affiliate',
  team_member: 'team_member',
  sales_team_member: 'sales_team_member',
  payer: 'payer',
}

export const ACTIVE_PROFILES = {
  seller: 'seller',
  publisher: 'publisher',
  eloPublisher: 'ablefy_affiliate',
  teamMember: 'team_member',
  salesTeamMember: 'sales_team_member',
  payer: 'payer',
  manager: 'manager',
  shop: 'shop',
}

export const UNACTIVE_PROFILES = {
  manager: 'manager',
  reseller: 'reseller',
}

export const getProfileDetails = () => ({
  seller: {
    icon: 'fas fa-shopping-basket',
    title: I18n.t('react.shared.seller'),
  },
  publisher: {
    icon: 'fas fa-handshake',
    title: I18n.t('react.shared.publisher_profile'),
  },
  eloPublisher: {
    icon: 'fas fa-handshake',
    title: I18n.t('react.shared.elo_publisher'),
  },
  teamMember: {
    icon: 'fas fa-user-plus',
    title: I18n.t('react.shared.team_member'),
  },
  salesTeamMember: {
    icon: 'fas fa-shopping-basket',
    title: I18n.t('react.shared.sales_team_member'),
  },
  payer: {
    icon: 'fas fa-credit-card',
    title: I18n.t('react.shared.payer'),
  },
  manager: {
    icon: 'fas fa-cog',
    title: I18n.t('react.shared.manager'),
  },
  ablefyAffiliate: {
    icon: 'fas fa-handshake',
    title: I18n.t('react.shared.elo_publisher'),
  },
})
export const getProfileTypesDetails = () => {
  const profileDetails = getProfileDetails()
  return [
    {
      name: PROFILES.seller,
      icon: `${profileDetails.seller.icon} profile-icons--seller`,
      title: profileDetails.seller.title,
    },
    {
      name: PROFILES.publisher,
      icon: `${profileDetails.publisher.icon} profile-icons--publisher`,
      title: profileDetails.publisher.title,
    },
    {
      name: PROFILES.eloPublisher,
      icon: `${profileDetails.eloPublisher.icon} profile-icons--elo-publisher`,
      title: profileDetails.eloPublisher.title,
    },
    {
      name: PROFILES.teamMember,
      icon: `${profileDetails.teamMember.icon} profile-icons--team-member`,
      title: profileDetails.teamMember.title,
    },
    {
      name: PROFILES.salesTeamMember,
      icon: `${profileDetails.salesTeamMember.icon} profile-icons--team-member`,
      title: profileDetails.salesTeamMember.title,
    },
    {
      name: PROFILES.payer,
      icon: `${profileDetails.payer.icon} profile-icons--payer`,
      title: profileDetails.payer.title,
    },
    {
      name: UNACTIVE_PROFILES.manager,
      icon: `${profileDetails.manager.icon} profile-icons--manager`,
      title: profileDetails.manager.title,
    },
  ]
}

export const REQUIRED_PROFILE_FIELDS = ['city', 'countryCode', 'firstName', 'lastName', 'street', 'streetNumber', 'zip']

export const getNewProfileRequiredFields = (userProfile, isEEACountry) => {
  const { countryCode, vatPayer, businessType, taxNoType } = userProfile

  const isEntityType = businessType === BUSINESS_TYPES.entity
  const isTaxIdentification = taxNoType === TAX_SWITCHER_KEYS.taxIdentificationNumber

  const commonRequiredFields = REQUIRED_PROFILE_FIELDS.filter((item) => item !== 'streetNumber')
  const birthdayField = !isEntityType && isEEACountry ? ['birthday'] : []
  const entityFields = isEntityType ? ['company', 'region'] : []
  const taxNoAndIssuanceCountryFields =
    isEEACountry || countryCode === 'UK' ? [isTaxIdentification ? 'taxIdentNo' : 'taxNo', 'issuanceCountryCode'] : []
  const reasonVatField = vatPayer === NO ? ['vatExemptReason'] : []

  return [
    'businessType',
    'phone',
    'vatPayer',
    ...commonRequiredFields,
    ...entityFields,
    ...birthdayField,
    ...taxNoAndIssuanceCountryFields,
    ...reasonVatField,
  ]
}

export const REQUIRED_PROFILE_FORMIK_FIELDS = [
  'city',
  'payerCountry',
  'firstName',
  'lastName',
  'street',
  'streetNumber',
  'zip',
  'phone',
]

export const PROFILE_TYPES = {
  seller: 'seller',
  publisher: 'publisher',
  elo_publisher: 'eloPublisher',
  team_member: 'teamMember',
  sales_team_member: 'salesTeamMember',
  payer: 'payer',
  manager: 'manager',
  ablefy_affiliate: 'eloPublisher',
}

export const PAYMENT_PROFILE_TYPES = {
  [PROFILES.eloPublisher]: 'EloPublisher',
  [PROFILES.payer]: 'Payer',
  [PROFILES.publisher]: 'Publisher',
  [PROFILES.seller]: 'Seller',
  [PROFILES.teamMember]: 'TeamMember',
  [PROFILES.salesTeamMember]: 'SalesTeamMember',
  [UNACTIVE_PROFILES.reseller]: 'Reseller',
}
export const PAYMENT_PROFILE_TYPES_VALUES = Object.values(PAYMENT_PROFILE_TYPES)

export const VAT_SERVICE_STATES = {
  throttled: 'MS_MAX_CONCURRENT_REQ',
  unavailable: 'MS_UNAVAILABLE',
  invalidInput: 'INVALID_INPUT',
}

export const STARTED_WITH_ELOPAGE_KEY = 'started_with_elopage'

export const PROFILE_VAT_CHANGE_STATUS = {
  pending: 'pending',
  inProcess: 'in_process',
}

export const PROFILE_TYPE_COOKIE_NAME = 'cabinet_profile_type'
